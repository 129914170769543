import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    fragment AlbumDetail on Album {\n  id\n  name\n  description\n  buyLink\n  melonLink\n  appleMusicLink\n  vibeLink\n  bugsLink\n  spotifyLink\n  youtubeMusicLink\n  releaseDate\n  images {\n    formats\n  }\n}\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    fragment AlbumListItem on Album {\n  id\n  name\n  musician {\n    name\n  }\n  images {\n    formats\n  }\n}\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    fragment HomeLiveListItem on Live {\n  id\n  title\n  startDate\n  endDate\n  stage {\n    id\n    name\n    defaultLivePoster {\n      formats\n    }\n  }\n  musicians {\n    id\n    name\n    useSlug\n    slug\n  }\n  liveStreaming {\n    id\n  }\n  posters {\n    id\n    formats\n    mime\n    url\n  }\n  isCanceled\n}\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n    fragment LinkListItem on Link {\n  id\n  title\n  linkUrl\n  publishDate\n  isRemoved\n  textColor\n  backgroundColor\n  order\n}\n    "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        '\n    fragment LinkHome on LinkHome {\n  id\n  backgroundImage {\n    id\n    formats\n  }\n  backgroundColor\n  fontColor\n  links(\n    limit: 12\n    sort: "order"\n    where: {isRemoved: false, _or: [{publishDate_null: true}, {publishDate_lte: $now}]}\n  ) {\n    ...LinkListItem\n  }\n}\n    ',
        ""
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n    fragment GenreFilterItem on Genre {\n  id\n  name\n}\n    "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n    fragment LiveEventListItem on Live {\n  id\n  title\n  musicians {\n    id\n    name\n    useSlug\n    slug\n  }\n  posters {\n    formats\n  }\n  startDate\n  endDate\n  isCanceled\n}\n    "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n    fragment MusicianListItem on Musician {\n  id\n  name\n  nameEn\n  nameJp\n  isSolo\n  profileImage {\n    id\n    formats\n  }\n  members {\n    id\n    name\n    profileImage {\n      id\n      formats\n    }\n  }\n  youtubeChannelLink\n  twitterLink\n  useSlug\n  slug\n}\n    "
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n    fragment LivePoster on Live {\n  title\n  posters {\n    id\n    formats\n    mime\n    url\n  }\n  isCanceled\n}\n    "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n    fragment LiveMutationResult on Live {\n  id\n  title\n  startDate\n  endDate\n  description\n  priceInfo\n  stage {\n    id\n    name\n    address\n    placeLink\n    youtubeChannelLink\n  }\n  createdBy {\n    id\n    username\n    email\n  }\n  liveStreaming {\n    id\n    videoId\n    streamingType\n    streamingLink\n  }\n  purchaseTicketLink\n  musicians {\n    ...MusicianListItem\n  }\n  ...LivePoster\n}\n    ",
        "\n",
        ""
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n    fragment StageFilterItem on Stage {\n  id\n  name\n  address\n}\n    "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n    fragment LiveInMusician on Musician {\n  id\n  name\n  profileImage {\n    formats\n  }\n  useSlug\n  slug\n}\n    "
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n    fragment FestivalItemLive on Live {\n  id\n  title\n  startDate\n  endDate\n  isCanceled\n  ...LivePoster\n}\n    ",
        ""
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        '\n    fragment FestivalItem on FestivalItem {\n  id\n  date\n  stageByFestival {\n    id\n    name\n    isLiveHall\n    youtubeChannelLink\n    useSlug\n    slug\n  }\n  stageAliasName\n  stageDescription\n  enableTimeTable\n  description\n  relatedLive {\n    ...FestivalItemLive\n  }\n  festivalItemTimeTables(sort: "liveTime:asc") {\n    id\n    liveTime\n    musician {\n      id\n      name\n      nameEn\n      nameJp\n      profileImage {\n        formats\n      }\n      useSlug\n      slug\n    }\n    festivalProgram {\n      programName\n    }\n  }\n  liveStreaming {\n    id\n    videoId\n    streamingType\n    streamingLink\n  }\n}\n    ',
        ""
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        '\n    fragment FestivalDetail on Festival {\n  id\n  festivalItems(sort: "date:asc") {\n    ...FestivalItem\n  }\n}\n    ',
        ""
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
function _templateObject15() {
    var data = _tagged_template_literal([
        "\n    fragment ArticleListItem on Article {\n  id\n  title\n  link\n  publishDate\n  lives {\n    id\n    title\n  }\n  musicians {\n    id\n    name\n    profileImage {\n      id\n      formats\n    }\n    useSlug\n    slug\n  }\n  isRemoved\n}\n    "
    ]);
    _templateObject15 = function _templateObject15() {
        return data;
    };
    return data;
}
function _templateObject16() {
    var data = _tagged_template_literal([
        '\n    fragment LiveListItem on Live {\n  id\n  title\n  startDate\n  endDate\n  description\n  priceInfo\n  stage {\n    id\n    name\n    youtubeChannelLink\n    defaultLivePoster {\n      formats\n    }\n    useSlug\n    slug\n  }\n  musicians {\n    ...LiveInMusician\n  }\n  liveStreaming {\n    id\n    videoId\n    streamingLink\n    streamingType\n  }\n  ...LivePoster\n  festival {\n    ...FestivalDetail\n  }\n  relatedLives(sort: "startDate") {\n    id\n    title\n    startDate\n    endDate\n    musicians {\n      id\n      name\n      useSlug\n      slug\n    }\n    posters {\n      id\n      formats\n      mime\n      url\n    }\n  }\n  articles(sort: "publishDate:DESC", limit: 12, where: {isRemoved: false}) {\n    ...ArticleListItem\n  }\n  priceInfo\n  purchaseTicketLink\n  isCanceled\n  eventPageLink\n}\n    ',
        "\n",
        "\n",
        "\n",
        ""
    ]);
    _templateObject16 = function _templateObject16() {
        return data;
    };
    return data;
}
function _templateObject17() {
    var data = _tagged_template_literal([
        "\n    fragment LiveStreamingItem on LiveStreaming {\n  id\n  videoId\n  streamingType\n  streamingLink\n  live {\n    ...LiveListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject17 = function _templateObject17() {
        return data;
    };
    return data;
}
function _templateObject18() {
    var data = _tagged_template_literal([
        "\n    fragment MusicianGenre on Genre {\n  id\n  name\n}\n    "
    ]);
    _templateObject18 = function _templateObject18() {
        return data;
    };
    return data;
}
function _templateObject19() {
    var data = _tagged_template_literal([
        "\n    fragment SNSLink on Musician {\n  twitterLink\n  instagramLink\n  spotifyLink\n  melonLink\n  appleMusicLink\n  soundcloudLink\n  facebookLink\n  bandcampLink\n  youtubeChannelLink\n}\n    "
    ]);
    _templateObject19 = function _templateObject19() {
        return data;
    };
    return data;
}
function _templateObject20() {
    var data = _tagged_template_literal([
        "\n    fragment MusicianHeader on Musician {\n  id\n  name\n  nameEn\n  nameJp\n  isSolo\n  bio\n  profileImage {\n    id\n    formats\n  }\n  headerImage {\n    id\n    formats\n  }\n  genres {\n    ...MusicianGenre\n  }\n  ...SNSLink\n}\n    ",
        "\n",
        ""
    ]);
    _templateObject20 = function _templateObject20() {
        return data;
    };
    return data;
}
function _templateObject21() {
    var data = _tagged_template_literal([
        "\n    fragment SimpleMusicianDetail on Musician {\n  id\n  name\n  nameEn\n  nameJp\n  bio\n  profileImage {\n    id\n    formats\n  }\n  headerImage {\n    id\n    formats\n  }\n  ...SNSLink\n  slug\n  useSlug\n}\n    ",
        ""
    ]);
    _templateObject21 = function _templateObject21() {
        return data;
    };
    return data;
}
function _templateObject22() {
    var data = _tagged_template_literal([
        "\n    fragment AlbumsSong on Song {\n  id\n  name\n  musicVideoLink\n}\n    "
    ]);
    _templateObject22 = function _templateObject22() {
        return data;
    };
    return data;
}
function _templateObject23() {
    var data = _tagged_template_literal([
        "\n    fragment MusiciansAlbum on Album {\n  id\n  name\n  description\n  albumType\n  buyLink\n  images {\n    id\n    formats\n  }\n  songs(where: {isRemoved: false}) {\n    ...AlbumsSong\n  }\n  melonLink\n  vibeLink\n  bugsLink\n  appleMusicLink\n  youtubeMusicLink\n  spotifyLink\n  releaseDate\n}\n    ",
        ""
    ]);
    _templateObject23 = function _templateObject23() {
        return data;
    };
    return data;
}
function _templateObject24() {
    var data = _tagged_template_literal([
        "\n    fragment ProductItem on Product {\n  id\n  name\n  productType\n  purchaseLink\n  description\n  price\n  isSoldOut\n  image {\n    id\n    formats\n  }\n}\n    "
    ]);
    _templateObject24 = function _templateObject24() {
        return data;
    };
    return data;
}
function _templateObject25() {
    var data = _tagged_template_literal([
        '\n    fragment MusicianDetail on Musician {\n  id\n  name\n  nameEn\n  nameJp\n  isSolo\n  bio\n  profileImage {\n    id\n    formats\n  }\n  headerImage {\n    id\n    formats\n  }\n  genres {\n    ...MusicianGenre\n  }\n  members {\n    name\n    instagramLink\n    twitterLink\n  }\n  albums(sort: "releaseDate:DESC", where: {isRemoved: false}) {\n    ...MusiciansAlbum\n  }\n  lives(sort: "startDate:DESC", where: {isRemoved: false}) {\n    id\n    title\n    startDate\n    endDate\n    description\n    priceInfo\n    stage {\n      id\n      name\n    }\n    ...LivePoster\n  }\n  products(where: {isRemoved: false}) {\n    ...ProductItem\n  }\n  ...SNSLink\n  articles(sort: "publishDate:DESC", limit: 12, where: {isRemoved: false}) {\n    ...ArticleListItem\n  }\n  slug\n  useSlug\n}\n    ',
        "\n",
        "\n",
        "\n",
        "\n",
        "\n",
        ""
    ]);
    _templateObject25 = function _templateObject25() {
        return data;
    };
    return data;
}
function _templateObject26() {
    var data = _tagged_template_literal([
        "\n    fragment RecordLabelFragment on RecordLabel {\n  id\n  slug\n  name\n  since\n  description\n  logo {\n    id\n    formats\n  }\n  coverImage {\n    id\n    formats\n  }\n  isRemoved\n}\n    "
    ]);
    _templateObject26 = function _templateObject26() {
        return data;
    };
    return data;
}
function _templateObject27() {
    var data = _tagged_template_literal([
        "\n    fragment StageListItem on Stage {\n  id\n  name\n  address\n  placeLink\n  youtubeChannelLink\n  instagramUsername\n}\n    "
    ]);
    _templateObject27 = function _templateObject27() {
        return data;
    };
    return data;
}
function _templateObject28() {
    var data = _tagged_template_literal([
        "\n    fragment StageDetail on Stage {\n  id\n  name\n  address\n  placeLink\n  description\n  youtubeChannelLink\n  homePageLink\n  phoneNumber\n  twitterUsername\n  instagramUsername\n  defaultLivePoster {\n    formats\n  }\n  logo {\n    formats\n  }\n  useSlug\n  slug\n}\n    "
    ]);
    _templateObject28 = function _templateObject28() {
        return data;
    };
    return data;
}
function _templateObject29() {
    var data = _tagged_template_literal([
        "\n    fragment TicketItem on Ticket {\n  id\n  ticketType\n  ticketTypeName\n  price\n  isSoldOut\n  watchingType\n  openDate\n  paymentType\n  infoLink\n  paymentLink\n  currencyCode\n}\n    "
    ]);
    _templateObject29 = function _templateObject29() {
        return data;
    };
    return data;
}
function _templateObject30() {
    var data = _tagged_template_literal([
        "\n    query albumList($sort: String, $where: JSON) {\n  albums(sort: $sort, where: $where) {\n    ...AlbumListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject30 = function _templateObject30() {
        return data;
    };
    return data;
}
function _templateObject31() {
    var data = _tagged_template_literal([
        "\n    query FindOneAlbum($id: ID!) {\n  album(id: $id) {\n    ...AlbumDetail\n    songs {\n      id\n      name\n      musicVideoLink\n    }\n    musician {\n      id\n      name\n      profileImage {\n        id\n        formats\n      }\n      useSlug\n      slug\n      albums(where: {isRemoved: false}) {\n        id\n        name\n        images {\n          formats\n        }\n      }\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject31 = function _templateObject31() {
        return data;
    };
    return data;
}
function _templateObject32() {
    var data = _tagged_template_literal([
        "\n    mutation RemoveAlbum($id: ID!) {\n  updateAlbum(input: {where: {id: $id}, data: {isRemoved: true}}) {\n    album {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject32 = function _templateObject32() {
        return data;
    };
    return data;
}
function _templateObject33() {
    var data = _tagged_template_literal([
        "\n    mutation CreateAlbum($albumInput: AlbumInput!) {\n  createAlbum(input: {data: $albumInput}) {\n    album {\n      ...AlbumDetail\n      songs {\n        id\n        name\n      }\n      musician {\n        id\n        name\n        profileImage {\n          formats\n        }\n        albums(where: {isRemoved: false}) {\n          id\n          name\n          images {\n            formats\n          }\n        }\n      }\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject33 = function _templateObject33() {
        return data;
    };
    return data;
}
function _templateObject34() {
    var data = _tagged_template_literal([
        "\n    mutation UpdateAlbum($id: ID!, $albumInput: editAlbumInput!) {\n  updateAlbum(input: {where: {id: $id}, data: $albumInput}) {\n    album {\n      ...AlbumDetail\n      songs {\n        id\n        name\n      }\n      musician {\n        id\n        name\n        profileImage {\n          formats\n        }\n        albums(where: {isRemoved: false}) {\n          id\n          name\n          images {\n            formats\n          }\n        }\n      }\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject34 = function _templateObject34() {
        return data;
    };
    return data;
}
function _templateObject35() {
    var data = _tagged_template_literal([
        "\n    query findArticleList($sort: String, $where: JSON, $start: Int, $limit: Int!) {\n  articles(sort: $sort, where: $where, start: $start, limit: $limit) {\n    ...ArticleListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject35 = function _templateObject35() {
        return data;
    };
    return data;
}
function _templateObject36() {
    var data = _tagged_template_literal([
        "\n    mutation CreateArticle($articleInput: ArticleInput!) {\n  createArticle(input: {data: $articleInput}) {\n    article {\n      ...ArticleListItem\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject36 = function _templateObject36() {
        return data;
    };
    return data;
}
function _templateObject37() {
    var data = _tagged_template_literal([
        "\n    mutation UpdateArticle($id: ID!, $articleInput: editArticleInput!) {\n  updateArticle(input: {where: {id: $id}, data: $articleInput}) {\n    article {\n      ...ArticleListItem\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject37 = function _templateObject37() {
        return data;
    };
    return data;
}
function _templateObject38() {
    var data = _tagged_template_literal([
        '\n    query findComments($musicianId: ID!, $target: String!) {\n  comments(\n    where: {musician: {id: $musicianId}, isRemoved: false, target: $target}\n    sort: "created_at:DESC"\n  ) {\n    id\n    content\n    target\n    created_at\n    createdBy {\n      username\n    }\n  }\n}\n    '
    ]);
    _templateObject38 = function _templateObject38() {
        return data;
    };
    return data;
}
function _templateObject39() {
    var data = _tagged_template_literal([
        "\n    mutation createComment($commentInput: CommentInput!) {\n  createComment(input: {data: $commentInput}) {\n    comment {\n      id\n      content\n      target\n      created_at\n      createdBy {\n        username\n      }\n    }\n  }\n}\n    "
    ]);
    _templateObject39 = function _templateObject39() {
        return data;
    };
    return data;
}
function _templateObject40() {
    var data = _tagged_template_literal([
        '\n    query findDonatedList {\n  donations(sort: "donatedDate:DESC") {\n    donatedUsername\n    donatedDate\n  }\n}\n    '
    ]);
    _templateObject40 = function _templateObject40() {
        return data;
    };
    return data;
}
function _templateObject41() {
    var data = _tagged_template_literal([
        "\n    mutation CreateEditLog($editLogInput: EditLogInput!) {\n  createEditLog(input: {data: $editLogInput}) {\n    editLog {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject41 = function _templateObject41() {
        return data;
    };
    return data;
}
function _templateObject42() {
    var data = _tagged_template_literal([
        '\n    query findFestivals($where: JSON) {\n  festivals(sort: "live.startDate:DESC", where: $where) {\n    live {\n      ...LiveListItem\n    }\n  }\n}\n    ',
        ""
    ]);
    _templateObject42 = function _templateObject42() {
        return data;
    };
    return data;
}
function _templateObject43() {
    var data = _tagged_template_literal([
        "\n    mutation CreateGenre($genreInput: GenreInput!) {\n  createGenre(input: {data: $genreInput}) {\n    genre {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject43 = function _templateObject43() {
        return data;
    };
    return data;
}
function _templateObject44() {
    var data = _tagged_template_literal([
        '\n    query HomeLiveContents($limit: Int!, $todayStart: String!, $todayEnd: String!) {\n  currentFestivals: lives(\n    sort: "startDate:asc"\n    limit: $limit\n    where: {endDate_gte: $todayStart, isRemoved: false, festival_null: false}\n  ) {\n    ...LiveListItem\n  }\n  todayLives: lives(\n    sort: "startDate:asc"\n    limit: $limit\n    where: {startDate_gte: $todayStart, endDate_lte: $todayEnd, isRemoved: false, isCanceled: false, festival_null: true}\n  ) {\n    ...HomeLiveListItem\n  }\n  upcomingLives: lives(\n    sort: "startDate:asc"\n    limit: $limit\n    where: {startDate_gt: $todayEnd, isRemoved: false, festival_null: true}\n  ) {\n    ...HomeLiveListItem\n  }\n}\n    ',
        "\n",
        ""
    ]);
    _templateObject44 = function _templateObject44() {
        return data;
    };
    return data;
}
function _templateObject45() {
    var data = _tagged_template_literal([
        '\n    query HomeContents($limit: Int!) {\n  recentlyReleasedAlbums: albums(\n    sort: "releaseDate:desc"\n    limit: $limit\n    where: {isRemoved: false, musician_ne: null}\n  ) {\n    ...AlbumListItem\n    releaseDate\n    musician {\n      name\n    }\n  }\n  recentlyArticles: articles(\n    sort: "publishDate:desc"\n    limit: $limit\n    where: {isRemoved: false}\n  ) {\n    ...ArticleListItem\n  }\n}\n    ',
        "\n",
        ""
    ]);
    _templateObject45 = function _templateObject45() {
        return data;
    };
    return data;
}
function _templateObject46() {
    var data = _tagged_template_literal([
        "\n    mutation createLink($linkInput: LinkInput!) {\n  createLink(input: {data: $linkInput}) {\n    link {\n      ...LinkListItem\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject46 = function _templateObject46() {
        return data;
    };
    return data;
}
function _templateObject47() {
    var data = _tagged_template_literal([
        "\n    mutation updateLink($id: ID!, $linkInput: editLinkInput!) {\n  updateLink(input: {data: $linkInput, where: {id: $id}}) {\n    link {\n      ...LinkListItem\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject47 = function _templateObject47() {
        return data;
    };
    return data;
}
function _templateObject48() {
    var data = _tagged_template_literal([
        '\n    query fetchArtistLinkTargets($slug: String!) {\n  musician: musicianBySlug(slug: $slug) {\n    lives(sort: "startDate:DESC", where: {isRemoved: false}) {\n      ...LiveListItem\n    }\n    albums(sort: "releaseDate:DESC", where: {isRemoved: false}) {\n      ...MusiciansAlbum\n    }\n    products(where: {isRemoved: false}) {\n      ...ProductItem\n    }\n    articles(sort: "publishDate:DESC", limit: 12, where: {isRemoved: false}) {\n      ...ArticleListItem\n    }\n    ...SNSLink\n  }\n}\n    ',
        "\n",
        "\n",
        "\n",
        "\n",
        ""
    ]);
    _templateObject48 = function _templateObject48() {
        return data;
    };
    return data;
}
function _templateObject49() {
    var data = _tagged_template_literal([
        "\n    query findMusicianLinkHome($slug: String!, $now: DateTime!) {\n  musician: musicianBySlug(slug: $slug) {\n    id\n    name\n    bio\n    profileImage {\n      id\n      formats\n    }\n    headerImage {\n      id\n      formats\n    }\n    ...SNSLink\n    linkHome {\n      ...LinkHome\n    }\n  }\n}\n    ",
        "\n",
        ""
    ]);
    _templateObject49 = function _templateObject49() {
        return data;
    };
    return data;
}
function _templateObject50() {
    var data = _tagged_template_literal([
        "\n    mutation createLinkHome($linkHomeInput: LinkHomeInput!) {\n  createLinkHome(input: {data: $linkHomeInput}) {\n    linkHome {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject50 = function _templateObject50() {
        return data;
    };
    return data;
}
function _templateObject51() {
    var data = _tagged_template_literal([
        "\n    mutation updateLinkHome($id: ID!, $linkHomeInput: editLinkHomeInput!) {\n  updateLinkHome(input: {data: $linkHomeInput, where: {id: $id}}) {\n    linkHome {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject51 = function _templateObject51() {
        return data;
    };
    return data;
}
function _templateObject52() {
    var data = _tagged_template_literal([
        "\n    query liveList($sort: String, $where: JSON, $start: Int, $limit: Int!) {\n  lives(sort: $sort, where: $where, start: $start, limit: $limit) {\n    ...LiveListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject52 = function _templateObject52() {
        return data;
    };
    return data;
}
function _templateObject53() {
    var data = _tagged_template_literal([
        '\n    query FindOneLive($id: ID!) {\n  live(id: $id) {\n    ...LiveListItem\n    ...LivePoster\n    tickets {\n      ...TicketItem\n    }\n    stage {\n      id\n      name\n      address\n      placeLink\n      youtubeChannelLink\n      defaultLivePoster {\n        id\n        formats\n        mime\n        url\n      }\n    }\n    musicians {\n      ...MusicianListItem\n    }\n    liveStreaming {\n      id\n      videoId\n      streamingType\n      streamingLink\n    }\n    festival {\n      id\n      festivalItems(sort: "date:asc") {\n        ...FestivalItem\n      }\n    }\n  }\n}\n    ',
        "\n",
        "\n",
        "\n",
        "\n",
        ""
    ]);
    _templateObject53 = function _templateObject53() {
        return data;
    };
    return data;
}
function _templateObject54() {
    var data = _tagged_template_literal([
        '\n    query liveEventList($monthStartDateString: String!, $monthEndDateString: String!) {\n  lives(\n    sort: "startDate:ASC"\n    start: 0\n    limit: 200\n    where: {startDate_gte: $monthStartDateString, startDate_lte: $monthEndDateString, isCanceled: false}\n  ) {\n    ...LiveEventListItem\n  }\n}\n    ',
        ""
    ]);
    _templateObject54 = function _templateObject54() {
        return data;
    };
    return data;
}
function _templateObject55() {
    var data = _tagged_template_literal([
        "\n    query GenreFilterList {\n  genres {\n    ...GenreFilterItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject55 = function _templateObject55() {
        return data;
    };
    return data;
}
function _templateObject56() {
    var data = _tagged_template_literal([
        "\n    query StageFilterList {\n  stages(where: {isLiveHall: true}) {\n    ...StageFilterItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject56 = function _templateObject56() {
        return data;
    };
    return data;
}
function _templateObject57() {
    var data = _tagged_template_literal([
        "\n    mutation CreateLive($liveInput: LiveInput!) {\n  createLive(input: {data: $liveInput}) {\n    live {\n      ...LiveMutationResult\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject57 = function _templateObject57() {
        return data;
    };
    return data;
}
function _templateObject58() {
    var data = _tagged_template_literal([
        "\n    mutation UpdateLive($id: ID!, $liveInput: editLiveInput!) {\n  updateLive(input: {data: $liveInput, where: {id: $id}}) {\n    live {\n      ...LiveMutationResult\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject58 = function _templateObject58() {
        return data;
    };
    return data;
}
function _templateObject59() {
    var data = _tagged_template_literal([
        '\n    query findLiveByMusicianId($musicianId: ID!) {\n  lives(where: {musicians: {id: $musicianId}}, sort: "startDate:DESC") {\n    id\n    title\n    posters {\n      id\n      mime\n      url\n      formats\n    }\n    isCanceled\n  }\n}\n    '
    ]);
    _templateObject59 = function _templateObject59() {
        return data;
    };
    return data;
}
function _templateObject60() {
    var data = _tagged_template_literal([
        "\n    query liveStreamingList($where: JSON) {\n  liveStreamings(where: $where) {\n    ...LiveStreamingItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject60 = function _templateObject60() {
        return data;
    };
    return data;
}
function _templateObject61() {
    var data = _tagged_template_literal([
        "\n    mutation UpdateLiveStreaming($id: ID!, $streamingInput: editLiveStreamingInput!) {\n  updateLiveStreaming(input: {data: $streamingInput, where: {id: $id}}) {\n    liveStreaming {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject61 = function _templateObject61() {
        return data;
    };
    return data;
}
function _templateObject62() {
    var data = _tagged_template_literal([
        "\n    mutation CreateLiveStreaming($streamingInput: LiveStreamingInput!) {\n  createLiveStreaming(input: {data: $streamingInput}) {\n    liveStreaming {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject62 = function _templateObject62() {
        return data;
    };
    return data;
}
function _templateObject63() {
    var data = _tagged_template_literal([
        "\n    query findOneMusician($id: ID!) {\n  musician(id: $id) {\n    ...MusicianDetail\n  }\n}\n    ",
        ""
    ]);
    _templateObject63 = function _templateObject63() {
        return data;
    };
    return data;
}
function _templateObject64() {
    var data = _tagged_template_literal([
        "\n    query musicianList($sort: String, $name: String, $genres: [ID!], $start: Int, $limit: Int!) {\n  musicians(\n    sort: $sort\n    where: {isRemoved: false, genres: {id_in: $genres}, _or: [{name_contains: $name}, {nameEn_contains: $name}, {nameJp_contains: $name}]}\n    start: $start\n    limit: $limit\n  ) {\n    ...MusicianListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject64 = function _templateObject64() {
        return data;
    };
    return data;
}
function _templateObject65() {
    var data = _tagged_template_literal([
        "\n    query findMusiciansBy($where: JSON) {\n  musicians(where: $where) {\n    ...MusicianListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject65 = function _templateObject65() {
        return data;
    };
    return data;
}
function _templateObject66() {
    var data = _tagged_template_literal([
        "\n    query findOneMusicianBySlug($slug: String!) {\n  musician: musicianBySlug(slug: $slug) {\n    ...MusicianDetail\n  }\n}\n    ",
        ""
    ]);
    _templateObject66 = function _templateObject66() {
        return data;
    };
    return data;
}
function _templateObject67() {
    var data = _tagged_template_literal([
        "\n    query findMusicianListByName($name: String) {\n  musicians(\n    where: {_or: [{name_contains: $name}, {nameEn_contains: $name}, {nameJp_contains: $name}]}\n  ) {\n    ...MusicianListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject67 = function _templateObject67() {
        return data;
    };
    return data;
}
function _templateObject68() {
    var data = _tagged_template_literal([
        "\n    mutation createMusicianByName($name: String!) {\n  createMusician(input: {data: {name: $name}}) {\n    musician {\n      ...MusicianListItem\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject68 = function _templateObject68() {
        return data;
    };
    return data;
}
function _templateObject69() {
    var data = _tagged_template_literal([
        "\n    mutation CreateMusician($musicianInput: MusicianInput!) {\n  createMusician(input: {data: $musicianInput}) {\n    musician {\n      ...MusicianDetail\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject69 = function _templateObject69() {
        return data;
    };
    return data;
}
function _templateObject70() {
    var data = _tagged_template_literal([
        "\n    mutation UpdateMusician($id: ID!, $musicianInput: editMusicianInput!) {\n  updateMusician(input: {data: $musicianInput, where: {id: $id}}) {\n    musician {\n      ...MusicianDetail\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject70 = function _templateObject70() {
        return data;
    };
    return data;
}
function _templateObject71() {
    var data = _tagged_template_literal([
        "\n    query FindProductsByMusician($musicianId: ID!) {\n  musician(id: $musicianId) {\n    id\n    name\n    products(where: {isRemoved: false}) {\n      ...ProductItem\n    }\n    useSlug\n    slug\n  }\n}\n    ",
        ""
    ]);
    _templateObject71 = function _templateObject71() {
        return data;
    };
    return data;
}
function _templateObject72() {
    var data = _tagged_template_literal([
        "\n    mutation RemoveProduct($id: ID!) {\n  updateProduct(input: {where: {id: $id}, data: {isRemoved: true}}) {\n    product {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject72 = function _templateObject72() {
        return data;
    };
    return data;
}
function _templateObject73() {
    var data = _tagged_template_literal([
        "\n    mutation CreateProduct($productInput: ProductInput!) {\n  createProduct(input: {data: $productInput}) {\n    product {\n      ...ProductItem\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject73 = function _templateObject73() {
        return data;
    };
    return data;
}
function _templateObject74() {
    var data = _tagged_template_literal([
        "\n    mutation UpdateProduct($productInput: editProductInput!, $id: ID!) {\n  updateProduct(input: {data: $productInput, where: {id: $id}}) {\n    product {\n      ...ProductItem\n    }\n  }\n}\n    ",
        ""
    ]);
    _templateObject74 = function _templateObject74() {
        return data;
    };
    return data;
}
function _templateObject75() {
    var data = _tagged_template_literal([
        "\n    query findRecordLabelsBySlug($slug: String!) {\n  recordLabels(where: {slug: $slug}) {\n    ...RecordLabelFragment\n    musicians {\n      ...MusicianDetail\n    }\n    articles {\n      ...ArticleListItem\n    }\n  }\n}\n    ",
        "\n",
        "\n",
        ""
    ]);
    _templateObject75 = function _templateObject75() {
        return data;
    };
    return data;
}
function _templateObject76() {
    var data = _tagged_template_literal([
        "\n    query findRecordLabels {\n  recordLabels(where: {isRemoved: false}) {\n    ...RecordLabelFragment\n  }\n}\n    ",
        ""
    ]);
    _templateObject76 = function _templateObject76() {
        return data;
    };
    return data;
}
function _templateObject77() {
    var data = _tagged_template_literal([
        '\n    query search($limit: Int!, $keyword: String!) {\n  musicians(\n    sort: "id:asc"\n    limit: $limit\n    where: {_or: [{name_contains: $keyword}, {nameEn_contains: $keyword}, {nameJp_contains: $keyword}], isRemoved: false}\n  ) {\n    ...MusicianListItem\n  }\n  lives(\n    sort: "startDate:desc"\n    limit: $limit\n    where: {_or: [{title_contains: $keyword}, {description_contains: $keyword}, {musicians_null: true}, {musicians: {name_contains: $keyword}}, {musicians: {nameEn_contains: $keyword}}, {musicians: {nameJp_contains: $keyword}}, {stage: {name_contains: $keyword}}], isRemoved: false}\n  ) {\n    ...LiveListItem\n  }\n  albums(\n    sort: "id:asc"\n    limit: $limit\n    where: {_or: [{name_contains: $keyword}, {musician: {name_contains: $keyword}}, {musician: {nameEn_contains: $keyword}}, {musician: {nameJp_contains: $keyword}}, {songs: {name_contains: $keyword}}], isRemoved: false}\n  ) {\n    ...AlbumListItem\n  }\n}\n    ',
        "\n",
        "\n",
        ""
    ]);
    _templateObject77 = function _templateObject77() {
        return data;
    };
    return data;
}
function _templateObject78() {
    var data = _tagged_template_literal([
        "\n    mutation RemoveSong($id: ID!) {\n  updateSong(input: {where: {id: $id}, data: {isRemoved: true}}) {\n    song {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject78 = function _templateObject78() {
        return data;
    };
    return data;
}
function _templateObject79() {
    var data = _tagged_template_literal([
        "\n    mutation CreateSong($songInput: SongInput!) {\n  createSong(input: {data: $songInput}) {\n    song {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject79 = function _templateObject79() {
        return data;
    };
    return data;
}
function _templateObject80() {
    var data = _tagged_template_literal([
        "\n    mutation UpdateSong($id: ID!, $songInput: editSongInput!) {\n  updateSong(input: {where: {id: $id}, data: $songInput}) {\n    song {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject80 = function _templateObject80() {
        return data;
    };
    return data;
}
function _templateObject81() {
    var data = _tagged_template_literal([
        "\n    query stageList($where: JSON, $start: Int, $limit: Int) {\n  stages(where: $where, start: $start, limit: $limit) {\n    ...StageListItem\n  }\n}\n    ",
        ""
    ]);
    _templateObject81 = function _templateObject81() {
        return data;
    };
    return data;
}
function _templateObject82() {
    var data = _tagged_template_literal([
        "\n    mutation createStage($stageInput: StageInput!) {\n  createStage(input: {data: $stageInput}) {\n    stage {\n      id\n      name\n    }\n  }\n}\n    "
    ]);
    _templateObject82 = function _templateObject82() {
        return data;
    };
    return data;
}
function _templateObject83() {
    var data = _tagged_template_literal([
        "\n    mutation updateStage($id: ID!, $stageInput: editStageInput!) {\n  updateStage(input: {data: $stageInput, where: {id: $id}}) {\n    stage {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject83 = function _templateObject83() {
        return data;
    };
    return data;
}
function _templateObject84() {
    var data = _tagged_template_literal([
        '\n    query findStages($where: JSON, $sort: String, $start: Int, $limit: Int!, $liveWhere: JSON, $liveLimit: Int) {\n  stages(where: $where, sort: $sort, start: $start, limit: $limit) {\n    id\n    name\n    address\n    placeLink\n    youtubeChannelLink\n    useSlug\n    slug\n    lives(sort: "startDate:ASC", where: $liveWhere, limit: $liveLimit) {\n      id\n      title\n      startDate\n      endDate\n      isCanceled\n      ...LivePoster\n    }\n    defaultLivePoster {\n      formats\n    }\n    logo {\n      formats\n    }\n    updated_at\n  }\n}\n    ',
        ""
    ]);
    _templateObject84 = function _templateObject84() {
        return data;
    };
    return data;
}
function _templateObject85() {
    var data = _tagged_template_literal([
        '\n    query findOneStage($id: ID!, $where: JSON, $limit: Int) {\n  stage(id: $id) {\n    ...StageDetail\n    lives(sort: "startDate:DESC", limit: $limit, where: $where) {\n      ...LiveListItem\n    }\n  }\n}\n    ',
        "\n",
        ""
    ]);
    _templateObject85 = function _templateObject85() {
        return data;
    };
    return data;
}
function _templateObject86() {
    var data = _tagged_template_literal([
        "\n    query findOneStageByEdit($id: ID!) {\n  stage: stageWithEditable(id: $id) {\n    id\n    name\n    address\n    placeLink\n    description\n    youtubeChannelLink\n    homePageLink\n    phoneNumber\n    slug\n    useSlug\n    twitterUsername\n    instagramUsername\n    defaultLivePoster {\n      formats\n    }\n    logo {\n      formats\n    }\n  }\n}\n    "
    ]);
    _templateObject86 = function _templateObject86() {
        return data;
    };
    return data;
}
function _templateObject87() {
    var data = _tagged_template_literal([
        '\n    query stageInfo($stageId: ID!) {\n  stage(id: $stageId) {\n    lives(where: {isCanceled: false}, sort: "startDate:DESC") {\n      title\n      startDate\n      posters {\n        formats\n      }\n      purchaseTicketLink\n      musicians {\n        name\n      }\n      stage {\n        defaultLivePoster {\n          formats\n        }\n      }\n    }\n  }\n}\n    '
    ]);
    _templateObject87 = function _templateObject87() {
        return data;
    };
    return data;
}
function _templateObject88() {
    var data = _tagged_template_literal([
        '\n    query findOneStageBySlug($slug: String!, $where: JSON, $limit: Int) {\n  stage: stageBySlug(slug: $slug) {\n    ...StageDetail\n    lives(sort: "startDate:DESC", limit: $limit, where: $where) {\n      ...LiveListItem\n    }\n  }\n}\n    ',
        "\n",
        ""
    ]);
    _templateObject88 = function _templateObject88() {
        return data;
    };
    return data;
}
function _templateObject89() {
    var data = _tagged_template_literal([
        '\n    query statistics($musicianId: ID!, $startDate: String!, $endDate: String!) {\n  musician(id: $musicianId) {\n    ...MusicianHeader\n    lives(\n      where: {startDate_gte: $startDate, startDate_lte: $endDate, isRemoved: false, isCanceled: false}\n      sort: "startDate:ASC"\n      limit: 300\n    ) {\n      ...LiveListItem\n      musicians {\n        id\n        name\n        profileImage {\n          formats\n        }\n        lives {\n          id\n          title\n        }\n        slug\n      }\n    }\n    albums(\n      where: {releaseDate_gte: $startDate, releaseDate_lte: $endDate, isRemoved: false}\n      sort: "releaseDate:ASC"\n    ) {\n      name\n      releaseDate\n      images {\n        formats\n      }\n    }\n    canceledLives: lives(\n      where: {startDate_gte: $startDate, endDate_lte: $endDate, isCanceled: true}\n      sort: "startDate:ASC"\n      limit: 300\n    ) {\n      id\n      title\n      startDate\n      endDate\n      posters {\n        formats\n      }\n    }\n  }\n}\n    ',
        "\n",
        ""
    ]);
    _templateObject89 = function _templateObject89() {
        return data;
    };
    return data;
}
function _templateObject90() {
    var data = _tagged_template_literal([
        "\n    mutation login($email: String!, $password: String!) {\n  login(input: {identifier: $email, password: $password}) {\n    jwt\n    user {\n      username\n    }\n  }\n}\n    "
    ]);
    _templateObject90 = function _templateObject90() {
        return data;
    };
    return data;
}
function _templateObject91() {
    var data = _tagged_template_literal([
        "\n    query ProfileMe {\n  me: self {\n    id\n    username\n    confirmed\n    blocked\n    role {\n      name\n      description\n      type\n    }\n    editableStages {\n      id\n    }\n    editableMusicians {\n      id\n    }\n    editableRecordLabels {\n      id\n    }\n  }\n}\n    "
    ]);
    _templateObject91 = function _templateObject91() {
        return data;
    };
    return data;
}
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
var defaultOptions = {};
export var EnumAlbumAlbumtype;
(function(EnumAlbumAlbumtype) {
    EnumAlbumAlbumtype["EP"] = "EP";
    EnumAlbumAlbumtype["FULL_ALBUM"] = "FULL_ALBUM";
    EnumAlbumAlbumtype["MINI_ALBUM"] = "MINI_ALBUM";
    EnumAlbumAlbumtype["OST"] = "OST";
    EnumAlbumAlbumtype["SINGLE"] = "SINGLE";
})(EnumAlbumAlbumtype || (EnumAlbumAlbumtype = {}));
export var EnumCommentTarget;
(function(EnumCommentTarget) {
    EnumCommentTarget["COMMON"] = "COMMON";
    EnumCommentTarget["STATISTICS_2021"] = "STATISTICS_2021";
})(EnumCommentTarget || (EnumCommentTarget = {}));
export var EnumComponentlinklinkhomeidTargettype;
(function(EnumComponentlinklinkhomeidTargettype) {
    EnumComponentlinklinkhomeidTargettype["ARTIST"] = "ARTIST";
    EnumComponentlinklinkhomeidTargettype["RECORD_LABEL"] = "RECORD_LABEL";
    EnumComponentlinklinkhomeidTargettype["STAGE"] = "STAGE";
})(EnumComponentlinklinkhomeidTargettype || (EnumComponentlinklinkhomeidTargettype = {}));
export var EnumEditlogChangedata;
(function(EnumEditlogChangedata) {
    EnumEditlogChangedata["ALBUM"] = "ALBUM";
    EnumEditlogChangedata["LIVE"] = "LIVE";
    EnumEditlogChangedata["MUSICIAN"] = "MUSICIAN";
    EnumEditlogChangedata["RECORD_LABEL"] = "RECORD_LABEL";
    EnumEditlogChangedata["STAGE"] = "STAGE";
})(EnumEditlogChangedata || (EnumEditlogChangedata = {}));
export var EnumLinkhomeLinkhometype;
(function(EnumLinkhomeLinkhometype) {
    EnumLinkhomeLinkhometype["ARTIST"] = "ARTIST";
    EnumLinkhomeLinkhometype["RECORD_LABEL"] = "RECORD_LABEL";
    EnumLinkhomeLinkhometype["VENUE"] = "VENUE";
})(EnumLinkhomeLinkhometype || (EnumLinkhomeLinkhometype = {}));
export var EnumLivecollectionLivecollectiontype;
(function(EnumLivecollectionLivecollectiontype) {
    EnumLivecollectionLivecollectiontype["TOUR"] = "TOUR";
})(EnumLivecollectionLivecollectiontype || (EnumLivecollectionLivecollectiontype = {}));
export var EnumLivestreamingStreamingtype;
(function(EnumLivestreamingStreamingtype) {
    EnumLivestreamingStreamingtype["DIRECT_LINK"] = "DIRECT_LINK";
    EnumLivestreamingStreamingtype["YOUTUBE"] = "YOUTUBE";
    EnumLivestreamingStreamingtype["YOUTUBE_MUAICIAN_CHANNEL"] = "YOUTUBE_MUAICIAN_CHANNEL";
    EnumLivestreamingStreamingtype["YOUTUBE_STAGE_CHANNEL"] = "YOUTUBE_STAGE_CHANNEL";
})(EnumLivestreamingStreamingtype || (EnumLivestreamingStreamingtype = {}));
export var EnumLiveupdatelogType;
(function(EnumLiveupdatelogType) {
    EnumLiveupdatelogType["CANCELED"] = "CANCELED";
    EnumLiveupdatelogType["DELAYED"] = "DELAYED";
    EnumLiveupdatelogType["TICKETING_OPEN"] = "TICKETING_OPEN";
})(EnumLiveupdatelogType || (EnumLiveupdatelogType = {}));
export var EnumProductProducttype;
(function(EnumProductProducttype) {
    EnumProductProducttype["ALBUM"] = "ALBUM";
    EnumProductProducttype["GOODS"] = "GOODS";
})(EnumProductProducttype || (EnumProductProducttype = {}));
export var EnumTicketCurrencycode;
(function(EnumTicketCurrencycode) {
    EnumTicketCurrencycode["JPY"] = "JPY";
    EnumTicketCurrencycode["KRW"] = "KRW";
    EnumTicketCurrencycode["USD"] = "USD";
})(EnumTicketCurrencycode || (EnumTicketCurrencycode = {}));
export var EnumTicketPaymenttype;
(function(EnumTicketPaymenttype) {
    EnumTicketPaymenttype["ACCOUNT_TRANSFER"] = "ACCOUNT_TRANSFER";
    EnumTicketPaymenttype["ON_SITE"] = "ON_SITE";
    EnumTicketPaymenttype["USE_INDISTREET"] = "USE_INDISTREET";
})(EnumTicketPaymenttype || (EnumTicketPaymenttype = {}));
export var EnumTicketTickettype;
(function(EnumTicketTickettype) {
    EnumTicketTickettype["FREE"] = "FREE";
    EnumTicketTickettype["ONLINE_RESERVATION"] = "ONLINE_RESERVATION";
    EnumTicketTickettype["ON_SITE_RESERVATION"] = "ON_SITE_RESERVATION";
})(EnumTicketTickettype || (EnumTicketTickettype = {}));
export var EnumTicketWatchingtype;
(function(EnumTicketWatchingtype) {
    EnumTicketWatchingtype["BOTH"] = "BOTH";
    EnumTicketWatchingtype["OFFLINE"] = "OFFLINE";
    EnumTicketWatchingtype["ONLINE"] = "ONLINE";
})(EnumTicketWatchingtype || (EnumTicketWatchingtype = {}));
export var EnumVideoSourcetype;
(function(EnumVideoSourcetype) {
    EnumVideoSourcetype["YOUTUBE"] = "YOUTUBE";
})(EnumVideoSourcetype || (EnumVideoSourcetype = {}));
export var EnumVideoVideotype;
(function(EnumVideoVideotype) {
    EnumVideoVideotype["LIVE"] = "LIVE";
    EnumVideoVideotype["MUSIC_VIDEO"] = "MUSIC_VIDEO";
})(EnumVideoVideotype || (EnumVideoVideotype = {}));
export var PublicationState;
(function(PublicationState) {
    PublicationState["LIVE"] = "LIVE";
    PublicationState["PREVIEW"] = "PREVIEW";
})(PublicationState || (PublicationState = {}));
export var AlbumDetailFragmentDoc = gql(_templateObject());
export var AlbumListItemFragmentDoc = gql(_templateObject1());
export var HomeLiveListItemFragmentDoc = gql(_templateObject2());
export var LinkListItemFragmentDoc = gql(_templateObject3());
export var LinkHomeFragmentDoc = gql(_templateObject4(), LinkListItemFragmentDoc);
export var GenreFilterItemFragmentDoc = gql(_templateObject5());
export var LiveEventListItemFragmentDoc = gql(_templateObject6());
export var MusicianListItemFragmentDoc = gql(_templateObject7());
export var LivePosterFragmentDoc = gql(_templateObject8());
export var LiveMutationResultFragmentDoc = gql(_templateObject9(), MusicianListItemFragmentDoc, LivePosterFragmentDoc);
export var StageFilterItemFragmentDoc = gql(_templateObject10());
export var LiveInMusicianFragmentDoc = gql(_templateObject11());
export var FestivalItemLiveFragmentDoc = gql(_templateObject12(), LivePosterFragmentDoc);
export var FestivalItemFragmentDoc = gql(_templateObject13(), FestivalItemLiveFragmentDoc);
export var FestivalDetailFragmentDoc = gql(_templateObject14(), FestivalItemFragmentDoc);
export var ArticleListItemFragmentDoc = gql(_templateObject15());
export var LiveListItemFragmentDoc = gql(_templateObject16(), LiveInMusicianFragmentDoc, LivePosterFragmentDoc, FestivalDetailFragmentDoc, ArticleListItemFragmentDoc);
export var LiveStreamingItemFragmentDoc = gql(_templateObject17(), LiveListItemFragmentDoc);
export var MusicianGenreFragmentDoc = gql(_templateObject18());
export var SnsLinkFragmentDoc = gql(_templateObject19());
export var MusicianHeaderFragmentDoc = gql(_templateObject20(), MusicianGenreFragmentDoc, SnsLinkFragmentDoc);
export var SimpleMusicianDetailFragmentDoc = gql(_templateObject21(), SnsLinkFragmentDoc);
export var AlbumsSongFragmentDoc = gql(_templateObject22());
export var MusiciansAlbumFragmentDoc = gql(_templateObject23(), AlbumsSongFragmentDoc);
export var ProductItemFragmentDoc = gql(_templateObject24());
export var MusicianDetailFragmentDoc = gql(_templateObject25(), MusicianGenreFragmentDoc, MusiciansAlbumFragmentDoc, LivePosterFragmentDoc, ProductItemFragmentDoc, SnsLinkFragmentDoc, ArticleListItemFragmentDoc);
export var RecordLabelFragmentFragmentDoc = gql(_templateObject26());
export var StageListItemFragmentDoc = gql(_templateObject27());
export var StageDetailFragmentDoc = gql(_templateObject28());
export var TicketItemFragmentDoc = gql(_templateObject29());
export var AlbumListDocument = gql(_templateObject30(), AlbumListItemFragmentDoc);
/**
 * __useAlbumListQuery__
 *
 * To run a query within a React component, call `useAlbumListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlbumListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlbumListQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      where: // value for 'where'
 *   },
 * });
 */ export function useAlbumListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(AlbumListDocument, options);
}
export function useAlbumListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(AlbumListDocument, options);
}
export var FindOneAlbumDocument = gql(_templateObject31(), AlbumDetailFragmentDoc);
/**
 * __useFindOneAlbumQuery__
 *
 * To run a query within a React component, call `useFindOneAlbumQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneAlbumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneAlbumQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */ export function useFindOneAlbumQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindOneAlbumDocument, options);
}
export function useFindOneAlbumLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindOneAlbumDocument, options);
}
export var RemoveAlbumDocument = gql(_templateObject32());
/**
 * __useRemoveAlbumMutation__
 *
 * To run a mutation, you first call `useRemoveAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAlbumMutation, { data, loading, error }] = useRemoveAlbumMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */ export function useRemoveAlbumMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(RemoveAlbumDocument, options);
}
export var CreateAlbumDocument = gql(_templateObject33(), AlbumDetailFragmentDoc);
/**
 * __useCreateAlbumMutation__
 *
 * To run a mutation, you first call `useCreateAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlbumMutation, { data, loading, error }] = useCreateAlbumMutation({
 *   variables: {
 *      albumInput: // value for 'albumInput'
 *   },
 * });
 */ export function useCreateAlbumMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateAlbumDocument, options);
}
export var UpdateAlbumDocument = gql(_templateObject34(), AlbumDetailFragmentDoc);
/**
 * __useUpdateAlbumMutation__
 *
 * To run a mutation, you first call `useUpdateAlbumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlbumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlbumMutation, { data, loading, error }] = useUpdateAlbumMutation({
 *   variables: {
 *      id: // value for 'id'
 *      albumInput: // value for 'albumInput'
 *   },
 * });
 */ export function useUpdateAlbumMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateAlbumDocument, options);
}
export var FindArticleListDocument = gql(_templateObject35(), ArticleListItemFragmentDoc);
/**
 * __useFindArticleListQuery__
 *
 * To run a query within a React component, call `useFindArticleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindArticleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindArticleListQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      where: // value for 'where'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useFindArticleListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindArticleListDocument, options);
}
export function useFindArticleListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindArticleListDocument, options);
}
export var CreateArticleDocument = gql(_templateObject36(), ArticleListItemFragmentDoc);
/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      articleInput: // value for 'articleInput'
 *   },
 * });
 */ export function useCreateArticleMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateArticleDocument, options);
}
export var UpdateArticleDocument = gql(_templateObject37(), ArticleListItemFragmentDoc);
/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      articleInput: // value for 'articleInput'
 *   },
 * });
 */ export function useUpdateArticleMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateArticleDocument, options);
}
export var FindCommentsDocument = gql(_templateObject38());
/**
 * __useFindCommentsQuery__
 *
 * To run a query within a React component, call `useFindCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCommentsQuery({
 *   variables: {
 *      musicianId: // value for 'musicianId'
 *      target: // value for 'target'
 *   },
 * });
 */ export function useFindCommentsQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindCommentsDocument, options);
}
export function useFindCommentsLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindCommentsDocument, options);
}
export var CreateCommentDocument = gql(_templateObject39());
/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      commentInput: // value for 'commentInput'
 *   },
 * });
 */ export function useCreateCommentMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateCommentDocument, options);
}
export var FindDonatedListDocument = gql(_templateObject40());
/**
 * __useFindDonatedListQuery__
 *
 * To run a query within a React component, call `useFindDonatedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDonatedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDonatedListQuery({
 *   variables: {
 *   },
 * });
 */ export function useFindDonatedListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindDonatedListDocument, options);
}
export function useFindDonatedListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindDonatedListDocument, options);
}
export var CreateEditLogDocument = gql(_templateObject41());
/**
 * __useCreateEditLogMutation__
 *
 * To run a mutation, you first call `useCreateEditLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditLogMutation, { data, loading, error }] = useCreateEditLogMutation({
 *   variables: {
 *      editLogInput: // value for 'editLogInput'
 *   },
 * });
 */ export function useCreateEditLogMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateEditLogDocument, options);
}
export var FindFestivalsDocument = gql(_templateObject42(), LiveListItemFragmentDoc);
/**
 * __useFindFestivalsQuery__
 *
 * To run a query within a React component, call `useFindFestivalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFestivalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFestivalsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */ export function useFindFestivalsQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindFestivalsDocument, options);
}
export function useFindFestivalsLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindFestivalsDocument, options);
}
export var CreateGenreDocument = gql(_templateObject43());
/**
 * __useCreateGenreMutation__
 *
 * To run a mutation, you first call `useCreateGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenreMutation, { data, loading, error }] = useCreateGenreMutation({
 *   variables: {
 *      genreInput: // value for 'genreInput'
 *   },
 * });
 */ export function useCreateGenreMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateGenreDocument, options);
}
export var HomeLiveContentsDocument = gql(_templateObject44(), LiveListItemFragmentDoc, HomeLiveListItemFragmentDoc);
/**
 * __useHomeLiveContentsQuery__
 *
 * To run a query within a React component, call `useHomeLiveContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeLiveContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeLiveContentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      todayStart: // value for 'todayStart'
 *      todayEnd: // value for 'todayEnd'
 *   },
 * });
 */ export function useHomeLiveContentsQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(HomeLiveContentsDocument, options);
}
export function useHomeLiveContentsLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(HomeLiveContentsDocument, options);
}
export var HomeContentsDocument = gql(_templateObject45(), AlbumListItemFragmentDoc, ArticleListItemFragmentDoc);
/**
 * __useHomeContentsQuery__
 *
 * To run a query within a React component, call `useHomeContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeContentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useHomeContentsQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(HomeContentsDocument, options);
}
export function useHomeContentsLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(HomeContentsDocument, options);
}
export var CreateLinkDocument = gql(_templateObject46(), LinkListItemFragmentDoc);
/**
 * __useCreateLinkMutation__
 *
 * To run a mutation, you first call `useCreateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkMutation, { data, loading, error }] = useCreateLinkMutation({
 *   variables: {
 *      linkInput: // value for 'linkInput'
 *   },
 * });
 */ export function useCreateLinkMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateLinkDocument, options);
}
export var UpdateLinkDocument = gql(_templateObject47(), LinkListItemFragmentDoc);
/**
 * __useUpdateLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkMutation, { data, loading, error }] = useUpdateLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      linkInput: // value for 'linkInput'
 *   },
 * });
 */ export function useUpdateLinkMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateLinkDocument, options);
}
export var FetchArtistLinkTargetsDocument = gql(_templateObject48(), LiveListItemFragmentDoc, MusiciansAlbumFragmentDoc, ProductItemFragmentDoc, ArticleListItemFragmentDoc, SnsLinkFragmentDoc);
/**
 * __useFetchArtistLinkTargetsQuery__
 *
 * To run a query within a React component, call `useFetchArtistLinkTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchArtistLinkTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchArtistLinkTargetsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */ export function useFetchArtistLinkTargetsQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FetchArtistLinkTargetsDocument, options);
}
export function useFetchArtistLinkTargetsLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FetchArtistLinkTargetsDocument, options);
}
export var FindMusicianLinkHomeDocument = gql(_templateObject49(), SnsLinkFragmentDoc, LinkHomeFragmentDoc);
/**
 * __useFindMusicianLinkHomeQuery__
 *
 * To run a query within a React component, call `useFindMusicianLinkHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMusicianLinkHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMusicianLinkHomeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      now: // value for 'now'
 *   },
 * });
 */ export function useFindMusicianLinkHomeQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindMusicianLinkHomeDocument, options);
}
export function useFindMusicianLinkHomeLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindMusicianLinkHomeDocument, options);
}
export var CreateLinkHomeDocument = gql(_templateObject50());
/**
 * __useCreateLinkHomeMutation__
 *
 * To run a mutation, you first call `useCreateLinkHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkHomeMutation, { data, loading, error }] = useCreateLinkHomeMutation({
 *   variables: {
 *      linkHomeInput: // value for 'linkHomeInput'
 *   },
 * });
 */ export function useCreateLinkHomeMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateLinkHomeDocument, options);
}
export var UpdateLinkHomeDocument = gql(_templateObject51());
/**
 * __useUpdateLinkHomeMutation__
 *
 * To run a mutation, you first call `useUpdateLinkHomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkHomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkHomeMutation, { data, loading, error }] = useUpdateLinkHomeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      linkHomeInput: // value for 'linkHomeInput'
 *   },
 * });
 */ export function useUpdateLinkHomeMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateLinkHomeDocument, options);
}
export var LiveListDocument = gql(_templateObject52(), LiveListItemFragmentDoc);
/**
 * __useLiveListQuery__
 *
 * To run a query within a React component, call `useLiveListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveListQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      where: // value for 'where'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useLiveListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(LiveListDocument, options);
}
export function useLiveListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(LiveListDocument, options);
}
export var FindOneLiveDocument = gql(_templateObject53(), LiveListItemFragmentDoc, LivePosterFragmentDoc, TicketItemFragmentDoc, MusicianListItemFragmentDoc, FestivalItemFragmentDoc);
/**
 * __useFindOneLiveQuery__
 *
 * To run a query within a React component, call `useFindOneLiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneLiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneLiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */ export function useFindOneLiveQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindOneLiveDocument, options);
}
export function useFindOneLiveLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindOneLiveDocument, options);
}
export var LiveEventListDocument = gql(_templateObject54(), LiveEventListItemFragmentDoc);
/**
 * __useLiveEventListQuery__
 *
 * To run a query within a React component, call `useLiveEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveEventListQuery({
 *   variables: {
 *      monthStartDateString: // value for 'monthStartDateString'
 *      monthEndDateString: // value for 'monthEndDateString'
 *   },
 * });
 */ export function useLiveEventListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(LiveEventListDocument, options);
}
export function useLiveEventListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(LiveEventListDocument, options);
}
export var GenreFilterListDocument = gql(_templateObject55(), GenreFilterItemFragmentDoc);
/**
 * __useGenreFilterListQuery__
 *
 * To run a query within a React component, call `useGenreFilterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenreFilterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenreFilterListQuery({
 *   variables: {
 *   },
 * });
 */ export function useGenreFilterListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(GenreFilterListDocument, options);
}
export function useGenreFilterListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(GenreFilterListDocument, options);
}
export var StageFilterListDocument = gql(_templateObject56(), StageFilterItemFragmentDoc);
/**
 * __useStageFilterListQuery__
 *
 * To run a query within a React component, call `useStageFilterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStageFilterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStageFilterListQuery({
 *   variables: {
 *   },
 * });
 */ export function useStageFilterListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(StageFilterListDocument, options);
}
export function useStageFilterListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(StageFilterListDocument, options);
}
export var CreateLiveDocument = gql(_templateObject57(), LiveMutationResultFragmentDoc);
/**
 * __useCreateLiveMutation__
 *
 * To run a mutation, you first call `useCreateLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveMutation, { data, loading, error }] = useCreateLiveMutation({
 *   variables: {
 *      liveInput: // value for 'liveInput'
 *   },
 * });
 */ export function useCreateLiveMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateLiveDocument, options);
}
export var UpdateLiveDocument = gql(_templateObject58(), LiveMutationResultFragmentDoc);
/**
 * __useUpdateLiveMutation__
 *
 * To run a mutation, you first call `useUpdateLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveMutation, { data, loading, error }] = useUpdateLiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      liveInput: // value for 'liveInput'
 *   },
 * });
 */ export function useUpdateLiveMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateLiveDocument, options);
}
export var FindLiveByMusicianIdDocument = gql(_templateObject59());
/**
 * __useFindLiveByMusicianIdQuery__
 *
 * To run a query within a React component, call `useFindLiveByMusicianIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLiveByMusicianIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLiveByMusicianIdQuery({
 *   variables: {
 *      musicianId: // value for 'musicianId'
 *   },
 * });
 */ export function useFindLiveByMusicianIdQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindLiveByMusicianIdDocument, options);
}
export function useFindLiveByMusicianIdLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindLiveByMusicianIdDocument, options);
}
export var LiveStreamingListDocument = gql(_templateObject60(), LiveStreamingItemFragmentDoc);
/**
 * __useLiveStreamingListQuery__
 *
 * To run a query within a React component, call `useLiveStreamingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveStreamingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveStreamingListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */ export function useLiveStreamingListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(LiveStreamingListDocument, options);
}
export function useLiveStreamingListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(LiveStreamingListDocument, options);
}
export var UpdateLiveStreamingDocument = gql(_templateObject61());
/**
 * __useUpdateLiveStreamingMutation__
 *
 * To run a mutation, you first call `useUpdateLiveStreamingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiveStreamingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiveStreamingMutation, { data, loading, error }] = useUpdateLiveStreamingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      streamingInput: // value for 'streamingInput'
 *   },
 * });
 */ export function useUpdateLiveStreamingMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateLiveStreamingDocument, options);
}
export var CreateLiveStreamingDocument = gql(_templateObject62());
/**
 * __useCreateLiveStreamingMutation__
 *
 * To run a mutation, you first call `useCreateLiveStreamingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveStreamingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveStreamingMutation, { data, loading, error }] = useCreateLiveStreamingMutation({
 *   variables: {
 *      streamingInput: // value for 'streamingInput'
 *   },
 * });
 */ export function useCreateLiveStreamingMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateLiveStreamingDocument, options);
}
export var FindOneMusicianDocument = gql(_templateObject63(), MusicianDetailFragmentDoc);
/**
 * __useFindOneMusicianQuery__
 *
 * To run a query within a React component, call `useFindOneMusicianQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneMusicianQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneMusicianQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */ export function useFindOneMusicianQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindOneMusicianDocument, options);
}
export function useFindOneMusicianLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindOneMusicianDocument, options);
}
export var MusicianListDocument = gql(_templateObject64(), MusicianListItemFragmentDoc);
/**
 * __useMusicianListQuery__
 *
 * To run a query within a React component, call `useMusicianListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMusicianListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMusicianListQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      name: // value for 'name'
 *      genres: // value for 'genres'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useMusicianListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(MusicianListDocument, options);
}
export function useMusicianListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(MusicianListDocument, options);
}
export var FindMusiciansByDocument = gql(_templateObject65(), MusicianListItemFragmentDoc);
/**
 * __useFindMusiciansByQuery__
 *
 * To run a query within a React component, call `useFindMusiciansByQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMusiciansByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMusiciansByQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */ export function useFindMusiciansByQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindMusiciansByDocument, options);
}
export function useFindMusiciansByLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindMusiciansByDocument, options);
}
export var FindOneMusicianBySlugDocument = gql(_templateObject66(), MusicianDetailFragmentDoc);
/**
 * __useFindOneMusicianBySlugQuery__
 *
 * To run a query within a React component, call `useFindOneMusicianBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneMusicianBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneMusicianBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */ export function useFindOneMusicianBySlugQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindOneMusicianBySlugDocument, options);
}
export function useFindOneMusicianBySlugLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindOneMusicianBySlugDocument, options);
}
export var FindMusicianListByNameDocument = gql(_templateObject67(), MusicianListItemFragmentDoc);
/**
 * __useFindMusicianListByNameQuery__
 *
 * To run a query within a React component, call `useFindMusicianListByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMusicianListByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMusicianListByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */ export function useFindMusicianListByNameQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindMusicianListByNameDocument, options);
}
export function useFindMusicianListByNameLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindMusicianListByNameDocument, options);
}
export var CreateMusicianByNameDocument = gql(_templateObject68(), MusicianListItemFragmentDoc);
/**
 * __useCreateMusicianByNameMutation__
 *
 * To run a mutation, you first call `useCreateMusicianByNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMusicianByNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMusicianByNameMutation, { data, loading, error }] = useCreateMusicianByNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */ export function useCreateMusicianByNameMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateMusicianByNameDocument, options);
}
export var CreateMusicianDocument = gql(_templateObject69(), MusicianDetailFragmentDoc);
/**
 * __useCreateMusicianMutation__
 *
 * To run a mutation, you first call `useCreateMusicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMusicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMusicianMutation, { data, loading, error }] = useCreateMusicianMutation({
 *   variables: {
 *      musicianInput: // value for 'musicianInput'
 *   },
 * });
 */ export function useCreateMusicianMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateMusicianDocument, options);
}
export var UpdateMusicianDocument = gql(_templateObject70(), MusicianDetailFragmentDoc);
/**
 * __useUpdateMusicianMutation__
 *
 * To run a mutation, you first call `useUpdateMusicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMusicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMusicianMutation, { data, loading, error }] = useUpdateMusicianMutation({
 *   variables: {
 *      id: // value for 'id'
 *      musicianInput: // value for 'musicianInput'
 *   },
 * });
 */ export function useUpdateMusicianMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateMusicianDocument, options);
}
export var FindProductsByMusicianDocument = gql(_templateObject71(), ProductItemFragmentDoc);
/**
 * __useFindProductsByMusicianQuery__
 *
 * To run a query within a React component, call `useFindProductsByMusicianQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductsByMusicianQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductsByMusicianQuery({
 *   variables: {
 *      musicianId: // value for 'musicianId'
 *   },
 * });
 */ export function useFindProductsByMusicianQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindProductsByMusicianDocument, options);
}
export function useFindProductsByMusicianLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindProductsByMusicianDocument, options);
}
export var RemoveProductDocument = gql(_templateObject72());
/**
 * __useRemoveProductMutation__
 *
 * To run a mutation, you first call `useRemoveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductMutation, { data, loading, error }] = useRemoveProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */ export function useRemoveProductMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(RemoveProductDocument, options);
}
export var CreateProductDocument = gql(_templateObject73(), ProductItemFragmentDoc);
/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      productInput: // value for 'productInput'
 *   },
 * });
 */ export function useCreateProductMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateProductDocument, options);
}
export var UpdateProductDocument = gql(_templateObject74(), ProductItemFragmentDoc);
/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      productInput: // value for 'productInput'
 *      id: // value for 'id'
 *   },
 * });
 */ export function useUpdateProductMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateProductDocument, options);
}
export var FindRecordLabelsBySlugDocument = gql(_templateObject75(), RecordLabelFragmentFragmentDoc, MusicianDetailFragmentDoc, ArticleListItemFragmentDoc);
/**
 * __useFindRecordLabelsBySlugQuery__
 *
 * To run a query within a React component, call `useFindRecordLabelsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRecordLabelsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRecordLabelsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */ export function useFindRecordLabelsBySlugQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindRecordLabelsBySlugDocument, options);
}
export function useFindRecordLabelsBySlugLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindRecordLabelsBySlugDocument, options);
}
export var FindRecordLabelsDocument = gql(_templateObject76(), RecordLabelFragmentFragmentDoc);
/**
 * __useFindRecordLabelsQuery__
 *
 * To run a query within a React component, call `useFindRecordLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRecordLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRecordLabelsQuery({
 *   variables: {
 *   },
 * });
 */ export function useFindRecordLabelsQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindRecordLabelsDocument, options);
}
export function useFindRecordLabelsLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindRecordLabelsDocument, options);
}
export var SearchDocument = gql(_templateObject77(), MusicianListItemFragmentDoc, LiveListItemFragmentDoc, AlbumListItemFragmentDoc);
/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */ export function useSearchQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(SearchDocument, options);
}
export var RemoveSongDocument = gql(_templateObject78());
/**
 * __useRemoveSongMutation__
 *
 * To run a mutation, you first call `useRemoveSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSongMutation, { data, loading, error }] = useRemoveSongMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */ export function useRemoveSongMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(RemoveSongDocument, options);
}
export var CreateSongDocument = gql(_templateObject79());
/**
 * __useCreateSongMutation__
 *
 * To run a mutation, you first call `useCreateSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSongMutation, { data, loading, error }] = useCreateSongMutation({
 *   variables: {
 *      songInput: // value for 'songInput'
 *   },
 * });
 */ export function useCreateSongMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateSongDocument, options);
}
export var UpdateSongDocument = gql(_templateObject80());
/**
 * __useUpdateSongMutation__
 *
 * To run a mutation, you first call `useUpdateSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSongMutation, { data, loading, error }] = useUpdateSongMutation({
 *   variables: {
 *      id: // value for 'id'
 *      songInput: // value for 'songInput'
 *   },
 * });
 */ export function useUpdateSongMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateSongDocument, options);
}
export var StageListDocument = gql(_templateObject81(), StageListItemFragmentDoc);
/**
 * __useStageListQuery__
 *
 * To run a query within a React component, call `useStageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStageListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useStageListQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(StageListDocument, options);
}
export function useStageListLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(StageListDocument, options);
}
export var CreateStageDocument = gql(_templateObject82());
/**
 * __useCreateStageMutation__
 *
 * To run a mutation, you first call `useCreateStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStageMutation, { data, loading, error }] = useCreateStageMutation({
 *   variables: {
 *      stageInput: // value for 'stageInput'
 *   },
 * });
 */ export function useCreateStageMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(CreateStageDocument, options);
}
export var UpdateStageDocument = gql(_templateObject83());
/**
 * __useUpdateStageMutation__
 *
 * To run a mutation, you first call `useUpdateStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStageMutation, { data, loading, error }] = useUpdateStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stageInput: // value for 'stageInput'
 *   },
 * });
 */ export function useUpdateStageMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(UpdateStageDocument, options);
}
export var FindStagesDocument = gql(_templateObject84(), LivePosterFragmentDoc);
/**
 * __useFindStagesQuery__
 *
 * To run a query within a React component, call `useFindStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *      liveWhere: // value for 'liveWhere'
 *      liveLimit: // value for 'liveLimit'
 *   },
 * });
 */ export function useFindStagesQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindStagesDocument, options);
}
export function useFindStagesLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindStagesDocument, options);
}
export var FindOneStageDocument = gql(_templateObject85(), StageDetailFragmentDoc, LiveListItemFragmentDoc);
/**
 * __useFindOneStageQuery__
 *
 * To run a query within a React component, call `useFindOneStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneStageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useFindOneStageQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindOneStageDocument, options);
}
export function useFindOneStageLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindOneStageDocument, options);
}
export var FindOneStageByEditDocument = gql(_templateObject86());
/**
 * __useFindOneStageByEditQuery__
 *
 * To run a query within a React component, call `useFindOneStageByEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneStageByEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneStageByEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */ export function useFindOneStageByEditQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindOneStageByEditDocument, options);
}
export function useFindOneStageByEditLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindOneStageByEditDocument, options);
}
export var StageInfoDocument = gql(_templateObject87());
/**
 * __useStageInfoQuery__
 *
 * To run a query within a React component, call `useStageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStageInfoQuery({
 *   variables: {
 *      stageId: // value for 'stageId'
 *   },
 * });
 */ export function useStageInfoQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(StageInfoDocument, options);
}
export function useStageInfoLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(StageInfoDocument, options);
}
export var FindOneStageBySlugDocument = gql(_templateObject88(), StageDetailFragmentDoc, LiveListItemFragmentDoc);
/**
 * __useFindOneStageBySlugQuery__
 *
 * To run a query within a React component, call `useFindOneStageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneStageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneStageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */ export function useFindOneStageBySlugQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(FindOneStageBySlugDocument, options);
}
export function useFindOneStageBySlugLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(FindOneStageBySlugDocument, options);
}
export var StatisticsDocument = gql(_templateObject89(), MusicianHeaderFragmentDoc, LiveListItemFragmentDoc);
/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *      musicianId: // value for 'musicianId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */ export function useStatisticsQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(StatisticsDocument, options);
}
export function useStatisticsLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(StatisticsDocument, options);
}
export var LoginDocument = gql(_templateObject90());
/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */ export function useLoginMutation(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useMutation(LoginDocument, options);
}
export var ProfileMeDocument = gql(_templateObject91());
/**
 * __useProfileMeQuery__
 *
 * To run a query within a React component, call `useProfileMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileMeQuery({
 *   variables: {
 *   },
 * });
 */ export function useProfileMeQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useQuery(ProfileMeDocument, options);
}
export function useProfileMeLazyQuery(baseOptions) {
    var options = _object_spread({}, defaultOptions, baseOptions);
    return Apollo.useLazyQuery(ProfileMeDocument, options);
}
